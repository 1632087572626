import { render, staticRenderFns } from "./Representatives.vue?vue&type=template&id=148fb6c4&scoped=true&"
import script from "./Representatives.vue?vue&type=script&lang=js&"
export * from "./Representatives.vue?vue&type=script&lang=js&"
import style0 from "./Representatives.vue?vue&type=style&index=0&id=148fb6c4&lang=scss&scoped=true&"
import style1 from "./Representatives.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "148fb6c4",
  null
  
)

export default component.exports